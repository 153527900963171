<template>
  <tr>
    <td>{{ view.orderNumber }}</td>
    <td>{{ view.name }}</td>
    <td>{{ view.email }}</td>
    <td>{{ view.hospital }}</td>
    <td>
      <div v-if="view.video != null && view.video.video1 === true">
        <img src="@/assets/images/IA/icon_done.png" alt="do">
      </div>
      <div v-else>
        <img src="@/assets/images/IA/icon_do.png" alt="do">
      </div>
    </td>
    <td v-if="viewNumber > 1">
      <div v-if="view.video != null && view.video.video2 === true">
        <img src="@/assets/images/IA/icon_done.png" alt="do">
      </div>
      <div v-else>
        <img src="@/assets/images/IA/icon_do.png" alt="do">
      </div>
    </td>
    <td v-if="viewNumber > 1">
      <div v-if="view.video != null && view.video.video3 === true">
        <img src="@/assets/images/IA/icon_done.png" alt="do">
      </div>
      <div v-else>
        <img src="@/assets/images/IA/icon_do.png" alt="do">
      </div>
    </td>
    <td v-if="viewNumber > 1">
      <div v-if="view.video != null && view.video.video4 === true">
        <img src="@/assets/images/IA/icon_done.png" alt="do">
      </div>
      <div v-else>
        <img src="@/assets/images/IA/icon_do.png" alt="do">
      </div>
    </td>
    <td v-if="viewNumber > 1">
      <div v-if="view.video != null && view.video.video5 === true">
        <img src="@/assets/images/IA/icon_done.png" alt="do">
      </div>
      <div v-else>
        <img src="@/assets/images/IA/icon_do.png" alt="do">
      </div>
    </td>
    <td v-if="viewNumber > 1">
      <div v-if="view.video != null && view.video.video6 === true">
        <img src="@/assets/images/IA/icon_done.png" alt="do">
      </div>
      <div v-else>
        <img src="@/assets/images/IA/icon_do.png" alt="do">
      </div>
    </td>
    <td v-if="viewNumber > 1">
      <div v-if="view.video != null && view.video.video7 === true">
        <img src="@/assets/images/IA/icon_done.png" alt="do">
      </div>
      <div v-else>
        <img src="@/assets/images/IA/icon_do.png" alt="do">
      </div>
    </td>
    <td v-if="viewNumber > 7">
      <div v-if="view.video != null && view.video.video8 === true">
        <img src="@/assets/images/IA/icon_done.png" alt="do">
      </div>
      <div v-else>
        <img src="@/assets/images/IA/icon_do.png" alt="do">
      </div>
    </td>
    <td v-if="viewNumber > 7">
      <div v-if="view.video != null && view.video.video9 === true">
        <img src="@/assets/images/IA/icon_done.png" alt="do">
      </div>
      <div v-else>
        <img src="@/assets/images/IA/icon_do.png" alt="do">
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "EduCheckTr",
  props: {
    view: Object,
    viewNumber: Number,
  }
}
</script>

<style scoped>
.tbl-body td {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #000000;
  border: 1px solid #E1E3E6;
}

.tbl-body td:nth-child(1) {
  padding: 12px;
}

.tbl-body img {
  width: 20px;
}

</style>
