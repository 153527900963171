<template>
  <div class="main-container">
    <h5 class="main-title">교육 영상 학습 이력</h5>
    <div class="search-container">
      <h5 class="search-title">조회 옵션 선택</h5>
      <div class="search-box">
        <p class="search-sub-title">교육 영상 구분</p>
        <mdb-select
            :scroll=false
            outline
            v-model="oneOptions"
            @getValue="getSelectMainValue"
            class="search-select "/>
        <mdb-select
            v-if="mainValue === '상지'"
            :scroll=false
            outline
            v-model="twoOptions"
            @getValue="getSelectSubValue"
            class="search-select "/>
        <mdb-select
            v-if="mainValue === '하지'"
            :scroll=false
            outline
            v-model="threeOptions"
            @getValue="getSelectSubValue"
            class="search-select "/>
      </div>
      <div class="search-box" style="margin-top : 8px">
        <p class="search-sub-title">치료사 검색</p>
        <mdb-select
            :scroll=false
            outline
            v-model="userOptions"
            @getValue="getSelectUserValue"
            class="search-select "/>
        <div class="icon-input">
          <img src="@/assets/images/IA/icon_search.png" alt="search">
          <input v-model="search" placeholder="검색어를 입력하세요." class="custom-input-box search-input"/>
        </div>
        <button class="custom-btn-box search-btn" @click="searchData">
          <img src="@/assets/images/IA/icon_play_w.png" alt="play">
          이력 조회
        </button>
      </div>
    </div>

    <div class="table-container">
      <table class="tbl-box">
        <thead class="main-thead">
        <tr>
          <th>No.</th>
          <th>이름</th>
          <th>아이디(이메일)</th>
          <th>소속병원</th>
          <th :class="{numberBox : viewNumber === 1}" colspan="9">{{ videoName }}</th>
        </tr>
        </thead>
        <thead class="sub-thead">
        <tr v-if="viewNumber !== ''">
          <th colspan="4">영상 No.</th>
          <th :class="{numberBox : viewNumber === 1}">1</th>
          <th v-if="viewNumber > 1">2</th>
          <th v-if="viewNumber > 1">3</th>
          <th v-if="viewNumber > 1">4</th>
          <th v-if="viewNumber > 1">5</th>
          <th v-if="viewNumber > 1">6</th>
          <th v-if="viewNumber > 1">7</th>
          <th v-if="viewNumber > 7">8</th>
          <th v-if="viewNumber > 7">9</th>
        </tr>
        </thead>
        <tbody class="tbl-body">
        <EduCheckTr :viewNumber="viewNumber" :view="data.rows[i]" v-for="(a,i) in data.rows" :key="i"/>
        </tbody>
      </table>

    </div>
  </div>
</template>

<script>
import {mdbSelect} from "mdbvue";
import EduCheckTr from "@/views/master/class/components/EduCheckTr";
import edu_title_columns from "@/assets/data/master/edu/edu_title_columns";
import {firebase} from "@/firebase/firebaseConfig";
import {getDate} from "@/lib/prettyDate";

export default {
  name: "EduCheckList",
  components: {
    EduCheckTr,
    mdbSelect,
  },
  data() {
    return {
      fbCollection: 'therapists',
      data: {
        columns: edu_title_columns,
        rows: []
      },
      search: '',
      userOptions: [
        {text: "전체", value: "전체", selected: true},
        {text: "이름", value: "이름"},
        {text: "아이디", value: "아이디"},
      ],
      userValue: '전체',
      oneOptions: [
        {text: "전체", value: "전체", selected: true},
        {text: "상지", value: "상지"},
        {text: "하지", value: "하지"},
      ],
      mainValue: '전체',
      twoOptions: [
        {text: "전체", value: "전체", selected: true},
        {text: "FM", value: "FM"},
      ],
      subValue: '전체',
      threeOptions: [
        {text: "전체", value: "전체", selected: true},
        {text: "FM_LOW", value: "FM_LOW"},
        {text: "BBS", value: "BBS"},
        {text: "TUG", value: "TUG"},
        {text: "FTSTS", value: "FTSTS"},
        {text: "SMWT", value: "SMWT"},
        {text: "TMWT", value: "TMWT"},
        {text: "DGI", value: "DGI"},
        {text: "ABC scale", value: "ABC scale"},
      ],
      userInfo: {},
      viewNumber: '',
      videoName: '',
    }
  },
  methods: {
    searchData() {
      const self = this;
      self.data.rows.splice(0);
      const db = firebase.firestore();
      let orderNumber = 1;
      let bsRef = db.collection(self.fbCollection);

      if (self.subValue === '전체') {
        alert('도구를 선택해 주세요.')
        self.viewNumber = ''
        self.videoName = ''
        return
      }

      if (self.userValue !== '전체' && self.search !== '') {
        if (self.userValue === '이름') bsRef = bsRef.where('name', '==', self.search)
        if (self.userValue === '아이디') bsRef = bsRef.where('email', '==', self.search)
      }

      bsRef
          .orderBy("regDate", "desc")
          .get().then(async (querySnapshot) => {
        if (querySnapshot.size !== 0) {
          querySnapshot.forEach((doc) => {
            const _data = doc.data();
            _data["docId"] = doc.id;
            _data.orderNumber = orderNumber++;
            const date = new Date(_data.regDate.seconds * 1000);
            _data.regDate = getDate(date);

            const userInfo = {
              orderNumber: _data.orderNumber,
              name: _data.name,
              email: _data.email,
              hospital: _data.hospitalInfo.name
            }
            self.viewNumber = 1

            if (self.subValue === 'FM') {
              userInfo['video'] = _data.fmVideo
              self.viewNumber = 9
              self.videoName = 'FM'
            }
            if (self.subValue === 'FM_LOW') {
              userInfo['video'] = _data.fmLowVideo
              self.viewNumber = 7
              self.videoName = 'FM_LOW'
            }
            if (self.subValue === 'BBS') {
              userInfo['video'] = _data.bbsVideo
              self.videoName = 'BBS'
            }
            if (self.subValue === 'TUG') {
              userInfo['video'] = _data.tugVideo
              self.videoName = 'TUG'
            }
            if (self.subValue === 'FTSTS') {
              userInfo['video'] = _data.ftstsVideo
              self.videoName = 'FTSTS'
            }
            if (self.subValue === 'TMWT') {
              userInfo['video'] = _data.tmwtVideo
              self.videoName = 'TMWT'
            }
            if (self.subValue === 'SMWT') {
              userInfo['video'] = _data.smwtVideo
              self.videoName = 'SMWT'
            }
            if (self.subValue === 'DGI') {
              userInfo['video'] = _data.dgiVideo
              self.videoName = 'DGI'
            }
            if (self.subValue === 'ABC scale') {
              userInfo['video'] = _data.abcVideo
              self.videoName = 'ABC scale'
            }
            self.data.rows.push(userInfo);

          });
        }
      })
    },
    getSelectUserValue(value) {
      this.userValue = value;
    },
    getSelectMainValue(value) {
      this.mainValue = value
    },
    getSelectSubValue(value) {
      this.subValue = value
    },
  }
}
</script>

<style scoped>
.main-container {
  background: white;
  padding: 44px 20px;
  min-width: 890px;
}

.main-title {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 30px;
}

.search-container {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 23px 20px;
}

.search-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin: 0 0 12px 0;
}

.search-sub-title {
  font-style: normal;
  font-weight: normal;
  min-width: 120px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #272833;
  margin: 0 10px 0 0;
}

.search-btn {
  width: 179px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.search-btn img {
  margin-right: 6px;
  width: 18px;
}

/deep/ .caret {
  display: none;
}

.search-select {
  margin-right: 10px;
}

/deep/ .search-select .md-form {
  width: 151px;
  padding: 0 !important;
  margin: 0 !important;
  background: url('../../../assets/images/IA/icon_down.png') no-repeat right 9px center;
  background-size: 18px 18px;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  letter-spacing: 0.0025em;
  color: #999999;
  margin: 0;
}

.sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px 0 20px;
}

.sub-container p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #272833;
  margin: 0;
}

.table-container {
  width: 100%;
  /*border: 1px solid #E1E3E6;*/
  /*box-sizing: border-box;*/
  /*border-radius: 8px;*/
}

.tbl-box {
  width: 100%;
  border: 1px solid #E1E3E6;
  border-radius: 8px;
  border-collapse: collapse;
}

.tbl-box th {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #000000;
  vertical-align: middle;
  text-align: center;
  border: 1px solid #E1E3E6;
  padding: 12px;
}

.main-thead .tbl-box th:first-child {
  max-width: 53px !important;
  padding-left: 10px !important;
}

.main-thead .tbl-box th:nth-child(2) {
  width: 99px;
}

.main-thead .tbl-box th:nth-child(3) {
  width: 200px;
}

.main-thead .tbl-box th:nth-child(4) {
  width: 132px;
}

.sub-thead {
  background: #f3f3f3;
}

.sub-thead th:first-child {
  max-width: 497px !important;
}

.sub-thead th {
  max-width: 48px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #000000;
}

.tbl-body td {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #000000;
  border: 1px solid #E1E3E6;
}

.tbl-body td:nth-child(1) {
  padding: 12px;
}

.tbl-body img {
  width: 20px;
}

.search-input {
  padding-left: 30px;
  background: #FFFFFF;
  font-size: 16px;
}

.icon-input {
  width: 100%;
  margin-right: 10px;
  position: relative;
}

/deep/ .icon-input ::placeholder {
  font-size: 16px !important;
}

.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.middle-container {
  margin: 34px 0 19px;
  padding: 0 20px;
}

.numberBox {
  width: 150px !important;
}
</style>
